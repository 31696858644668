.google_adsense_container_placeholder  {
  overflow: hidden;
  width:100%;
}
.adsbygoogle {
  display: block;
  margin:0 auto;
  min-width: 120px;
  min-height: 60px;
}
.google_adsense_container_placeholder .adsbygoogle {
  background: #ffc;
  border: 1rem solid #ee7;
  color: rgba(0,0,0,0.25);
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: center;
}
.adsbygoogle.homepage_under_featured_content {
  width:300px;
  height:250px;
}
@media (min-width: 648px) {
  .adsbygoogle.homepage_under_featured_content {
    width:600px;
    height:250px;
  }
}
@media (min-width:1018px) {
  .adsbygoogle.homepage_under_featured_content {
    width:970px;
    height:250px;
  }
}
@media (min-width: 1248px) {
  .adsbygoogle.homepage_under_featured_content {
    width:1200px;
    height:250px;
  }
}